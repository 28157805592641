import React, { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { HomeNavigation, MaintenanceNavigation } from "@modules";
import { GlobalState, NavigationActions, SplashScreenState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { ToggleThunks } from "@modules/toggle";

export const RootNavigator: React.FC = () => {
  const [webSocketURL, setWebSocketURL] = React.useState("");
  const [maintenance, setMaintenance] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const { isInitialLinkHandled, splashScreenState } = useSelector((state: GlobalState) => state.navigation);

  const { sendMessage, lastMessage } = useWebSocket(webSocketURL, {}, webSocketURL !== "");

  sendMessage(JSON.stringify({ action: "retrieveFeatures", application_id: "FOODI-MONOREPO", environment: window.config.FOODI_ENV ?? "LOCAL" }));

  useEffect(() => {
    const getToggle: any = async () => {
      return await dispatch(ToggleThunks.getToken({ type: "read" }));
    };

    if (webSocketURL === "" && window.config?.WEBSOCKET_URL) {
      getToggle().then((data: any) => {
        const token = data?.access_token;
        if (token) {
          const url = `wss://${window.config.WEBSOCKET_URL}/?token=${token}`;
          setWebSocketURL(url);
        }
      });
    }
  }, [dispatch, webSocketURL]);

  useEffect(() => {
    if (lastMessage) {
      const messageParse = JSON.parse(lastMessage.data);

      if (messageParse.type === "RETRIEVE") {
        const data = messageParse.data;

        const filterSiteMaintenance = data.filter((d: any) => d.feature_name === "SITE_MAINTENANCE")?.[0];

        if (filterSiteMaintenance !== undefined) {
          setMaintenance(filterSiteMaintenance.isActive);
        }
      }
    }
  }, [lastMessage?.data]);

  useEffect(() => {
    // hook to be run once when the app start to check if a deeplink opened
    if (!isInitialLinkHandled && splashScreenState === SplashScreenState.PLAYED) {
      dispatch(NavigationActions.setIsInitialLinkHandled(true));
    }
  }, [splashScreenState, isInitialLinkHandled, dispatch]);

  return maintenance ? <MaintenanceNavigation /> : <HomeNavigation />;
};
