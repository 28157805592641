import { CalendarDay, LeftArrow, RightArrow } from "@atomic";
import { ActiveOffer } from "@foodi/core";
import { useDevices } from "@hooks";
import {
  BookingActions,
  BookingPeriod,
  BookingViewModel,
  CalendarFlow,
  CalendarViewModel,
  OffersActions,
  OfferViewModel,
} from "@modules";
import { GlobalState, LoaderActions } from "@redux";
import { TestIDs } from "@utils";
import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import { useDispatch, useSelector } from "react-redux";

interface ICalendarDay {
  index: number;
  isSelected: boolean;
}
interface IProps {
  style?: ViewStyle;
  isModify: any;
  userLanguage?: string;
  calendarFlow: CalendarFlow;
  isRefillFromCart?: boolean;
}

export const CalendarDays: React.FC<IProps> = React.memo(
  ({ style, isModify, userLanguage, calendarFlow, isRefillFromCart }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = _styles(isMobile);

    const selectedDay = useSelector(
      (state: GlobalState) => state.booking?.selectedDay ?? 0
    );
    const [CalendarVM] = useState<CalendarViewModel>(new CalendarViewModel());
    const [offerVM] = useState<OfferViewModel>(new OfferViewModel(dispatch));
    const [bookingVM] = useState<BookingViewModel>(new BookingViewModel());
    const [days, setDays] = useState<ICalendarDay[]>();
    const [plusDays, setPlusDays] = useState<number>(0);
    const [currentReservation, setCurrentReservation] = useState(isModify);

    const selectedOffer = useSelector(
      (state: GlobalState) => state.offers.selectedOffer
    );
    const nextOrderableOffers = useSelector(
      (state: GlobalState) => state.offers.nextOrderableOffers
    );
    const selectedOfferSlot = useSelector(
      (state: GlobalState) => state.offers.selectedOfferSlot
    );
    const { bookingOffers, bookingPeriod } = useSelector(
      (state: GlobalState) => state.booking
    );

    const updateOffers = async () => {
      if (calendarFlow !== CalendarFlow.ORDER) return;

      dispatch(LoaderActions.setLoading(true));
      const { fullDate } = await CalendarVM.getDateInfo(
        selectedDay,
        userLanguage
      );
      const offerIndex = await offerVM.getOfferDayIndex(
        fullDate,
        nextOrderableOffers
      );
      const activeOffer: ActiveOffer = await offerVM.getOffer(
        nextOrderableOffers?.[offerIndex]?.id || ""
      );
      dispatch(OffersActions.setActiveOrderableOffer(activeOffer || null));
      dispatch(LoaderActions.setLoading(false));
    };

    const resetOffers = () => {
      dispatch(OffersActions.setSelectedOfferSlot(null));
      dispatch(OffersActions.initOrderItems());
      setCurrentReservation(null);
    };

    const handleLessDays = () => {
      setPlusDays(plusDays - CalendarVM.DAYS_NUMBER);
      dispatch(
        BookingActions.setSelectedDay(selectedDay - CalendarVM.DAYS_NUMBER)
      );
      resetOffers();
    };

    const handleMoreDays = () => {
      setPlusDays(plusDays + CalendarVM.DAYS_NUMBER);
      dispatch(
        BookingActions.setSelectedDay(selectedDay + CalendarVM.DAYS_NUMBER)
      );
      resetOffers();
    };

    useEffect(() => {
      setDays(CalendarVM.getUpdatedDays(selectedDay, plusDays));
      if (!isRefillFromCart) updateOffers();
    }, [selectedDay, plusDays]);

    useEffect(() => {
      if (!isRefillFromCart) {
        setPlusDays(0);
        dispatch(BookingActions.setSelectedDay(0));
      }
    }, [selectedOffer]);

    useEffect(() => {
      if (currentReservation && calendarFlow === CalendarFlow.BOOKING) {
        const getBookingDayIndex = bookingVM.getBookingDayIndex(
          currentReservation,
          bookingOffers
        );
        if (getBookingDayIndex > 4) {
          const weekEnd = bookingPeriod === BookingPeriod.WEEKLY_MON ? 2 : 0;
          setPlusDays(
            CalendarVM.DAYS_NUMBER *
              Math.floor(getBookingDayIndex / CalendarVM.DAYS_NUMBER)
          );
          dispatch(BookingActions.setSelectedDay(getBookingDayIndex + weekEnd));
        } else {
          dispatch(BookingActions.setSelectedDay(getBookingDayIndex));
        }
      }
    }, [currentReservation, bookingPeriod, bookingOffers]);

    useEffect(() => {
      if (isRefillFromCart && calendarFlow === CalendarFlow.ORDER) {
        const withdrawDate = offerVM.getWithdrawDateFromOfferSlot(
          selectedOfferSlot
        );
        const offerIndex = offerVM.getOfferDayIndex(
          withdrawDate,
          nextOrderableOffers
        );

        if (offerIndex > 4) {
          setPlusDays(
            CalendarVM.DAYS_NUMBER *
              Math.floor(offerIndex / CalendarVM.DAYS_NUMBER)
          );
          dispatch(BookingActions.setSelectedDay(offerIndex));
        } else {
          dispatch(BookingActions.setSelectedDay(offerIndex));
        }
      }
    }, [isRefillFromCart]);

    const onDaySelected = useCallback((dayNumber: number) => {
      dispatch(BookingActions.setSelectedDay(dayNumber + plusDays));
      resetOffers();
    }, []);

    const onScroll = (ev: any) => {
      if (!isMobile) return;

      if (ev.nativeEvent.state === State.END) {
        if (ev.nativeEvent.velocityX < 0 && canIncreaseCalendar) {
          handleMoreDays();
        }
        if (ev.nativeEvent.velocityX > 0 && canDecreaseCalendar) {
          handleLessDays();
        }
      }
    };

    const {
      canIncreaseCalendar,
      canDecreaseCalendar,
    } = CalendarVM.checkCalendarBehaviour(
      bookingPeriod,
      bookingOffers,
      selectedOffer,
      plusDays,
      calendarFlow
    );

    return (
      <PanGestureHandler onGestureEvent={onScroll} minDist={100}>
        <View
          style={[styles.container, style]}
          testID={TestIDs.components.calendarDays.views.container}
        >
          {canDecreaseCalendar && (
            <LeftArrow
              forwardTestID={TestIDs.restaurantDetail.actions.carouselLeftArrow}
              style={styles.leftArrow}
              onPress={handleLessDays}
            />
          )}
          {days?.map(({ index, isSelected }) => {
            const { fullDate } = CalendarVM.getDateInfo(index, userLanguage);

            const disabled =
              calendarFlow === CalendarFlow.ORDER
                ? offerVM.checkIfDayHasOffer(fullDate, nextOrderableOffers)
                : false;
            return (
              <CalendarDay
                key={index}
                index={index}
                isSelected={isSelected}
                forwardTestID={`${TestIDs.components.calendarDay.views.container}_${index}`}
                isDisabled={disabled}
                onSelect={onDaySelected}
              />
            );
          })}
          {canIncreaseCalendar && (
            <RightArrow
              forwardTestID={
                TestIDs.restaurantDetail.actions.carouselRightArrow
              }
              style={styles.rightArrow}
              onPress={handleMoreDays}
            />
          )}
        </View>
      </PanGestureHandler>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      alignSelf: "baseline",
    },
    leftArrow: {
      position: "absolute",
      left: isMobile ? 5 : -10,
      top: 10,
      zIndex: 1,
    },
    rightArrow: {
      position: "absolute",
      right: -10,
      top: 10,
      zIndex: 1,
    },
  });
