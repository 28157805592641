import { Title16 } from "@stylesheets";
import React, { useMemo } from "react";
import { Pressable, View, StyleSheet, ScrollView, TextInput } from "react-native";
import { Icon } from "@atomic/atoms/Icon";
import { IListItem } from "../../screens/Homepage";
import { DropDownSelectRow } from "../index";
import { Colors } from "@constants";
import _ from "lodash";
import styled from "styled-components/native";

interface IDropdownProps {
  type: string;
  list: IListItem[];
  selected?: string;
  colorize?: boolean;
  disabled?: boolean;
  selectIdHandler?: Function;
  staysOpen?: boolean;
  highlightWhenOpen?: boolean;
  handleCheckOption?: (school: IListItem, check: boolean) => void;
  allOptionsText?: string;
  optionsChecked?: IListItem[];
  hasErrors?: boolean;
  isDynamic?: boolean;
  searchable?: boolean;
}

export const DropDownSelect: React.FC<IDropdownProps> = React.memo(({
  selected,
  type,
  list,
  colorize,
  disabled,
  selectIdHandler,
  staysOpen = false,
  highlightWhenOpen = false,
  handleCheckOption,
  allOptionsText,
  optionsChecked,
  hasErrors,
  isDynamic,
  searchable
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [canOpen, setCanOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string | null>(null);

  let searchInputRef: TextInput | null = null;

  const handleOpenDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      if (searchable) {
        searchInputRef?.focus();
        setSearch('');
      }
    }
    if (isOpen) {
      setSearch(null);
      searchInputRef?.blur();
    }
  };

  const handleSelection = (props: any) => {
    if (selectIdHandler) {
      selectIdHandler(props);
      setIsOpen(false);
      setSearch(null);
      searchInputRef?.blur();
    }
  };

  React.useEffect(() => {
    setCanOpen(!!list.length);
  }, [list]);

  const handleOutOfFocus = () => {
    if (!staysOpen) {
      setIsOpen(false);
      setSearch(null);
      searchInputRef?.blur();
    }
  };

  let orderedList = _.sortBy(list, (item: IListItem) => item?.name?.toLowerCase());
  if (search) {
    orderedList = list.filter(({ name }) => fuzzyMatch(search.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase(), name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()));
  }


  const paintWhenClicked = isOpen && highlightWhenOpen;

  const styles = useMemo(() => _styles(
    highlightWhenOpen, !!selected, hasErrors, isDynamic, disabled, searchable
  ), [
    highlightWhenOpen, selected, hasErrors, isDynamic, disabled, searchable
  ]);

  const handleIsChecked = (id: string) => {
    return optionsChecked?.some((i: IListItem) => i.id === id);
  };

  const isAll = optionsChecked?.length === list.length;

  return (
      <div
        style={{ display: "flex", flexDirection: "column" }}
        onMouseLeave={handleOutOfFocus}
      >
        <Pressable
          style={[
            styles.dropDownSelect,
            styles.borderAfterSelection,
            disabled
              ? styles.unselectable
              : colorize || paintWhenClicked
              ? styles.pressSelect
              : styles.unselectable,
          ]}
          onPress={handleOpenDropdown}
          disabled={disabled || (searchable && list.length <= 1)}
        >
          {searchable ? <SearchField editable={list.length > 1} value={search || search === '' ? search : selected}
                                   onFocus={() => {
                                     if (list.length <= 1) {
                                       return;
                                     }
                                     setSearch('');
                                   }}
                                   onChangeText={(text: string) => {
                                     setSearch(text);
                                   }}
                                   ref={(input) => { searchInputRef = input; }}
          /> :
            <Title16
              color={!disabled ? "#090909" : "#666666"}
              bold={paintWhenClicked}
              numberOfLines={1}
            >
              {selected && selected !== "" ? selected : type}
            </Title16>
          }
          <Icon
            size={12}
            style={[styles.icon, searchable && list.length <= 1 ? { display: 'none' } : {}]}
            color={!disabled ? "#090909" : "#666666"}
            name={
              isOpen && !disabled && canOpen
                ? "Accordeon_arrow_open_black"
                : "Accordeon_arrow_default_black"
            }
          />
        </Pressable>
        {isOpen && canOpen && !!list.length && (
          <ScrollView style={styles.dropBoxContainer}>
            {allOptionsText && (
              <>
                <DropDownSelectRow
                  key={`all_${isAll}`}
                  id="all"
                  value={allOptionsText}
                  selectHandler={selectIdHandler}
                  handleCheckOption={handleCheckOption}
                  isChecked={isAll}
                />
                <View style={styles.allOptionsContainer} />
              </>
            )}
            {orderedList.map((item: IListItem) => {
              const isChecked = handleIsChecked(item.id);
              return (
                <DropDownSelectRow
                  key={`${item.id}_${isChecked}`}
                  id={item.id}
                  value={item.name}
                  selectHandler={handleSelection}
                  handleCheckOption={handleCheckOption}
                  isChecked={isChecked}
                />
              )
            })}
          </ScrollView>
        )}
      </div>
  );
});

interface SearchFieldProps{
  color?: string;
  bold?: boolean;
  verticalAlign?: string;
}

const SearchField = styled.TextInput<SearchFieldProps>`
    font-size: 16px;
    color: ${({ color }) => color || Colors.foodiBlack};
    font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
    line-height: 20;
    ${({ verticalAlign }) => verticalAlign ? `vertical-align: ${verticalAlign}` : ''};
    outline-style: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;


const _styles = (
  highlightWhenOpen?: boolean, selected?: boolean, hasErrors?: boolean, isDynamic?: boolean, disabled?: boolean, searchable?: boolean
) =>
  StyleSheet.create({
    dropDownSelect: {
      borderColor: disabled ? Colors.gray1 : hasErrors ? Colors.redError : Colors.foodiBlack,
      borderWidth: 1,
      height: 32,
      borderRadius: 10,
      padding: 5,
      paddingLeft: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    unselectable: {
      backgroundColor: "#F2F2F2",
    },
    icon: {
      padding: 5,
      paddingLeft: 10,
      fontSize: 12,
    },
    dropBoxContainer: {
      maxHeight: (isDynamic || highlightWhenOpen) ? 245 : 'unset',
      width: highlightWhenOpen || searchable ? "100%" : "max-content",
      position: "absolute",
      borderColor: "#090909",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: "white",
      paddingTop: 5,
      paddingBottom: 5,
      top: 32,
    },
    allOptionsContainer: {
      boxSizing: 'border-box',
      height: 1,
      backgroundColor: Colors.darkGrey,
      marginHorizontal: 20,
      marginVertical: 2.5
    },
    pressSelect: {
      backgroundColor: Colors.foodiDefault,
      borderWidth: 0,
    },
    borderAfterSelection: highlightWhenOpen && selected ? {
      boxSizing: 'border-box',
      border: `2px solid ${Colors.foodiDefault}`,
      borderRadius: 8,
    } : {}
  });

const fuzzyMatch = (pattern: string, str: string) => {
  pattern = '.*' + pattern.split('').join('.*') + '.*';
  const re = new RegExp(pattern, 'i');
  return re.test(str);
};
