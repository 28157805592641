"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MFA_CONFIG = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_MFA_CONFIG = client_1.gql `
  mutation updateMFAConfig($input: InputUpdateUser) {
    updateUser(input: $input) {
      id
      mfaEnabled
    }
  }
`;
