import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Container,
  Text,
  Title,
  ProductDescription,
  Row,
  Separator,
  SmallDescription,
  GoBackText,
  GoBackContainerMobile,
  SearchBar,
  SearchInput,
  LastPickupPointTitle,
  SectionTitle,
} from "./Cart.style";
import { QuotationSummary } from "@atomic/organisms/Cart/QuotationSummary";
import { CartSubmitButton } from "@atomic/organisms/Cart/CartSubmitButton";
import { CartErrors } from "@atomic/organisms/Cart/CartErrors";
import { CartPayment } from "@atomic/organisms/Cart/CartPayment";
import { ProductsInformations } from "@atomic/organisms/Cart/ProductsInformations";
import {
  CartSummaryAdmissionAndPrice,
  ICommonPos,
  OfferSlot,
  OfferTemplateWithdrawalType,
  OrderItem,
  OrderState,
  IPaymentTypes,
  IPickupPoint,
  PaymentMethod,
  ManagementMode,
  CashSystem,
} from "@foodi/core";
import { I18n } from "react-redux-i18n";
import { getDecodedId, getQueryParam, TestIDs } from "@utils";
import {
  CGU,
  OfferSlots,
  TableNumber,
  PickupPointsList,
  PickupPoint,
} from "@atomic";
import { GlobalState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeScreens,
  OrderViewModel,
  OfferViewModel,
  OffersActions,
  POSScreens,
  BookingActions,
  PointOfSaleActions,
  TransactionsActions,
  EdenredPaymentViewModel,
  ConfirmationPageActions,
  TableThunks,
  PickupPointsThunks,
} from "@modules";
import {
  Back,
  Search as SearchIcon,
} from "@assets";
import { NavigationProp, Route } from "@react-navigation/native";
import { View } from "react-native";
import { useDevices } from "@hooks";
import { isEmpty, isEqual, isNil } from "lodash";
import { useCartSelectors } from "./useCartSelectors";
import { OrderComment } from "@atomic/molecules/OrderComment";

interface ICart {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  products?: OrderItem[];
  isRefillFromCart?: boolean;
}

export const Cart: React.FC<ICart> = React.memo(
  ({ products: productsSelected, isRefillFromCart, navigation, route }) => {
    const { type }: any = route?.params || {};
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const [quotation, setQuotation] = useState<CartSummaryAdmissionAndPrice>();
    const [quotationError, setQuotationError] = useState();
    const [noBalanceError, setNoBalanceError] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [offerSlots, setOfferSlots] = useState<OfferSlot[]>();
    const [tableNumber, setTableNumber] = useState<number | undefined>(
      undefined
    );
    const [isValidTableNumber, setIsValidTableNumber] = useState<boolean>(
      false
    );
    const [paymentMethod, setPaymentMethod] = React.useState<string>();
    const [orderComment, setOrderComment] = React.useState<string>("");
    const [pickupPoints, setPickupPoints] = React.useState<IPickupPoint[]>([]);
    const [
      selectedPickupPoint,
      setSelectedPickupPoint,
    ] = React.useState<IPickupPoint>();
    const [searchPickupPoints, setSearchPickupPoints] = React.useState("");
    const [
      lastUsedPickupPoint,
      setLastUsedPickupPoint,
    ] = useState<IPickupPoint | null>(null);
    const [hasContainer, setHasContainer] = useState<boolean>(false);

    const [isOtherCardOpen, setIsOtherCardOpen] = useState<boolean>(false);

    const [offerVM] = useState(new OfferViewModel());

    const paymentMethodRef = React.useRef<string>();

    const {
      idGuest = "",
      badgeNumber,
      isHeartMealsRuleValid,
      balance,
      allowedBalance,
      idOffer,
      withdrawalType,
      offerSlot,
      cartCurrentInfo,
      language,
      edenredBalance,
      edenredToken,
      paymentTypes,
      selectedPointOfSale,
      idCashSystem,
      selectedOffer,
    } = useCartSelectors();

    const selectedActiveOffer = useSelector(
      (state: GlobalState) => state.offers.activeOrderableOffer
    );
    const { managementMode } =
      useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

    const idOfferTemplate = useSelector(
      (state: GlobalState) => state.offers?.selectedOffer?.id
    );

    const activeOrderableOfferDate = useSelector((state: GlobalState) => {
      // @ts-ignore
      const date = state.offers?.activeOrderableOffer
        ? state.offers?.activeOrderableOffer?.withdrawRange?.split("/")?.[0]
        : undefined;
      return moment(date).format("dddd DD/MM");
    });
    const [orderVM] = useState(new OrderViewModel(dispatch, idGuest || ""));

    const [edenredPaymentViewModel] = useState(
      new EdenredPaymentViewModel(dispatch)
    );

    const getEdenredToken = (event: MessageEvent<any>) => {
      const search = event.data;
      const code = getQueryParam(search, "code");
      if (code && !edenredToken?.accessToken) {
        edenredPaymentViewModel.fetchEdenredToken(code);
      }
    };
    const userHasBadge = !isNil(badgeNumber);

    const products =
      isRefillFromCart && isEmpty(productsSelected)
        ? cartCurrentInfo?.products
        : productsSelected;

    const isTableService =
      withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE;
    const isSeatClick =
      withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE;
    const isInstantCC =
      withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT;
    const isClickPickup =
      withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP;
    const isCatCSI = isTableService && idCashSystem === CashSystem.CSI;

    const [items, formulaItems] = orderVM.divideItemsFromFormulas(products)


    const formattedTotalPrice = useMemo(() => {
      return offerVM.computeTotalPrice(products);
    }, [products]);
    const priceValue = quotation?.totalPrice?.amount || formattedTotalPrice;

    const checkRechargeBadgeLater = orderVM.checkIfRefillAllowed(
      quotation,
      priceValue,
      balance ?? "",
      paymentMethod,
      allowedBalance ?? 0
    );

    const guestBalance = Math.max(Number(balance),allowedBalance?? 0)
    const checkLowBalance =
      !!quotation &&
      Number(priceValue?.replace(",", ".")) > guestBalance &&
      paymentMethod === PaymentMethod.Badge;

    const handlePickupPointsSearch = async () => {
      if (isClickPickup && searchPickupPoints && idOfferTemplate) {
        const ONLY_SPACES_REG_EX = /^\s*$/; // Don't search if value doesn't have any letters
        const stringHasLetters = (value: string) =>
          !ONLY_SPACES_REG_EX.test(value);

        if (
          !!idOfferTemplate &&
          !!searchPickupPoints &&
          stringHasLetters(searchPickupPoints) &&
          searchPickupPoints.trim().length >= 3
        ) {
          const data = await dispatch(
            PickupPointsThunks.getOfferTemplatePickupPoints({
              idOfferTemplate,
              search: searchPickupPoints.trim(),
            })
          );
          // @ts-ignore
          setPickupPoints(data?.pickupPoints);
        }
      }
    };

    useEffect(() => {
      if (isClickPickup) {
        if (!searchPickupPoints) {
          setPickupPoints([]);
          return;
        }
        handlePickupPointsSearch();
      }
    }, [searchPickupPoints]);

    const handleLastUsedPickupPoint = async () => {
      if (!lastUsedPickupPoint && !!idGuest && !!idOfferTemplate) {
        const data = await dispatch(
          PickupPointsThunks.getUserLastUsedPickupPoint({
            idGuest,
            idOfferTemplate,
          })
        );
        // @ts-ignore
        const pickupPoint = data?.pickupPoint;
        setLastUsedPickupPoint(pickupPoint);

        if (!selectedPickupPoint && pickupPoint) {
          setSelectedPickupPoint(pickupPoint);
        }
      }
    };

    const handleTableNumberChange = React.useCallback(
      (tableNumber: number | undefined, isValid: boolean) => {
        setTableNumber(tableNumber);
        setIsValidTableNumber(isValid);
      },
      []
    );

    const generateOfferSlots = async (idOffer: string) => {
      const slots = await orderVM.getOfferSlots(idOffer);
      setOfferSlots(slots);
    };

    const handleQuotationValues = async (paymentMethod?: string) => {
      setLoading(true);
      setQuotationError(undefined);
      setNoBalanceError(false);
      setQuotation(undefined);
      if (
        !userHasBadge &&
        selectedActiveOffer?.id &&
        paymentMethod !== PaymentMethod.OnSite &&
        paymentMethod !== PaymentMethod.Edenred && !isSeatClick && !isInstantCC
      ) {
        await generateOfferSlots(selectedActiveOffer.id);
      } else if (selectedActiveOffer?.id && (items || formulaItems)) {
        try {
          let quotationOrder;
          if (
            paymentMethod === PaymentMethod.OnSite &&
            selectedOffer?.withdrawalType !==
              OfferTemplateWithdrawalType.TABLE_SERVICE
          ) {
            quotationOrder = {
              admission: { amount: selectedOffer?.admission },
              subsidies: { amount: selectedOffer?.subsidies },
              fee: { amount: selectedOffer?.fee },
              totalPrice: {
                amount: formattedTotalPrice?.replace(",", "."),
              },
            };
          } else if (idGuest || isCatCSI || paymentMethod === PaymentMethod.Edenred) {
            let slots: OfferSlot[] = [];

            if (!isSeatClick && !isInstantCC) {
              // we need to know a timerange when calculation the quotation for offers in advance
              // otherwise it will always use the current day
              // Because of that we are sending a "mock" time slot just to get the correct quotation
              // The order withdrawRange gets replaced once the user chooses a slot
              slots = await orderVM.getOfferSlots(selectedActiveOffer.id);
            }

            const availableSlot = slots.find(
              ({ numOrders }) => numOrders === 0
            );
            const { order } = await orderVM.getQuotationFromOrder({
              idGuest:
                paymentMethod === PaymentMethod.OnSite && !idGuest
                  ? ""
                  : idGuest,
              idOffer: selectedActiveOffer.id,
              items,
              formulaItems,
              paymentMethod,
              withdrawRange: availableSlot?.withdrawRange,
              tableNumber,
            });
            quotationOrder = order;
          }
          if (quotationOrder) {
            const sanitizedQuotationOrder = orderVM.getSanitizedQuotationOrder(
              quotationOrder,
              paymentMethod,
              selectedOffer?.withdrawalType
            );
            setQuotationError(undefined);
            setNoBalanceError(false);
            setQuotation(sanitizedQuotationOrder);

              if (!isSeatClick && !isInstantCC) {
                  await generateOfferSlots(selectedActiveOffer.id);
              }
          }
        } catch (err: any) {
          setQuotationError(err);
        }
      }
      setLoading(false);
    };

    const handleBadgeRefill = () => {
      dispatch(TransactionsActions.setRefillFromCart(true));

      dispatch(
        PointOfSaleActions.setCartCurrentInfo({
          idOffer: selectedActiveOffer?.id,
          offerSlot,
          products,
          quotation,
          offerSlots,
          type,
        })
      );

      //@ts-ignore
      navigation.push(HomeScreens.BALANCE_SCREEN);
    };

    const checkBadgeFunds =
      checkRechargeBadgeLater &&
      !(isTableService && idCashSystem === CashSystem.CSI);

    const getEdenredAccessToken = async (): Promise<string | undefined> => {
      if (paymentMethod === IPaymentTypes.Edenred && edenredToken) {
        const token = await edenredPaymentViewModel.renewEdenredToken(
          edenredToken
        );

        if (token === null) {
          handleEdenredPayment(IPaymentTypes.Edenred);
          return undefined;
        }

        return token?.accessToken;
      }
    };

    const handleConfirmationOrder = async () => {
      if (checkLowBalance && managementMode === ManagementMode.PRE) {
        setNoBalanceError(true);
        return;
      }

      const accessToken = (await getEdenredAccessToken()) || undefined;
      setLoading(true);
      try {
        if (
          selectedActiveOffer?.id &&
          (offerSlot?.withdrawRange ||
            tableNumber ||
            isInstantCC ||
            selectedPickupPoint?.id)
        ) {
          const [items, formulaItems] = orderVM.divideItemsFromFormulas(products)
          //@ts-ignore
          const { order } = await orderVM.upsertOrder({
            idOffer: selectedActiveOffer?.id,
            idGuest:
              paymentMethod === PaymentMethod.OnSite && !idGuest ? "" : idGuest,
            items,
            formulaItems,
            withdrawRange: offerSlot?.withdrawRange,
            tableNumber: tableNumber,
            comment: orderComment,
            idPickupPoint: selectedPickupPoint?.id,
          });

          if ((quotation || offerSlots) && order?.id) {
            const confirmResult = await orderVM.confirmOrder({
              idOrder: order.id,
              newState: OrderState.ON_HOLD,
              paymentMethod,
              accessToken,
              tableNumber,
            });

            if (
              confirmResult &&
              paymentMethod === IPaymentTypes.Edenred &&
              edenredToken
            ) {
              edenredPaymentViewModel.getEdenredBalance(edenredToken);
            }

            const id = getDecodedId(confirmResult?.id);
            dispatch(BookingActions.setBookingSelected(false));
            dispatch(OffersActions.setSelectedOfferSlot(null));
            dispatch(PointOfSaleActions.setNewOrder(true));

            dispatch(
              ConfirmationPageActions.setConfirmationPageParams({
                id: id?.split(":")?.[1],
                orderId: confirmResult.id,
                isFirstTime: true,
                idPos: -1,
                offerSlot: "",
              })
            );

            //@ts-ignore
            navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
              screen: POSScreens.CONFIRMATION_PAGE,
            });
          }
        }
      } catch (err: any) {
        setQuotationError(err);
      } finally {
        setLoading(false);
      }
    };

    const canMakeOrder =
      (offerSlot?.withdrawRange ||
        (!!quotation && !!tableNumber) ||
        (!!quotation && !!selectedPickupPoint?.id) ||
        (isInstantCC && !!quotation)) &&
      paymentMethod;

    const onPress = () => {
      if (checkBadgeFunds) {
        return handleBadgeRefill;
      }

      if (canMakeOrder) {
        return handleConfirmationOrder;
      }

      return () => handleQuotationValues(paymentMethod);
    };

    const buttonLabel = (function () {
      if (checkBadgeFunds && paymentMethod !== PaymentMethod.OnSite) {
        return I18n.t("refill.refill");
      }
      if (quotation) {
        return I18n.t("homepage.restaurantCard.clickAndCollect");
      }
      return I18n.t(
        `restaurantDetail.cart.${quotationError ? "tryAgain" : "continue"}`
      );
    })();

    const handleGoBack = () => {
      dispatch(PointOfSaleActions.setFullCartStatus(false));
      dispatch(PointOfSaleActions.setMiniCartStatus(true));
    };

    const handleEdenredPayment = async (newPaymentMethod: string) => {
        setPaymentMethod(newPaymentMethod);
        // trigger quotation - for ticket FS-4350
        if (!loading || paymentMethod !== newPaymentMethod) {
          await handleQuotationValues(newPaymentMethod);
        }

        if (!edenredToken?.accessToken) {
          edenredPaymentViewModel.edenredAuthentication(language);
        }
  } ;

    const checkEdenredFunds =
      paymentMethod === IPaymentTypes.Edenred &&
      (edenredBalance === undefined ||
        Number(priceValue?.replace(",", ".")) > Number(edenredBalance));

    useEffect(() => {
      if (isRefillFromCart && cartCurrentInfo) {
        setQuotation(cartCurrentInfo?.quotation);
        setOfferSlots(cartCurrentInfo?.offerSlots);
        dispatch(OffersActions.setSelectedOfferSlot(cartCurrentInfo.offerSlot));
      }
    }, [cartCurrentInfo, isRefillFromCart]);

    // Not remove table number when is seat click offer
    useEffect(() => {
      if (quotation === undefined && !isSeatClick) {
        setTableNumber(undefined);
      }
    }, [quotation]);

    useEffect(() => {
      dispatch(TransactionsActions.setRefillFromCart(false));
      if (!isRefillFromCart) dispatch(OffersActions.setSelectedOfferSlot(null));
      if (edenredToken) edenredPaymentViewModel.getEdenredBalance(edenredToken);

      window.addEventListener("message", getEdenredToken);

      return () => {
        if (!isMobile && !isRefillFromCart) dispatch(OffersActions.initOrderItems());

        window.removeEventListener("message", getEdenredToken);
      };
    }, []);

    useEffect(() => {
      const idPos = (selectedPointOfSale as ICommonPos)?.id;
      if (isTableService && idCashSystem === CashSystem.CSI && !!idPos) {
        dispatch(TableThunks.checkTablesAvailability({ idPos: idPos }));
      }
    }, []);

    useEffect(() => {
      if (isClickPickup) {
        handleLastUsedPickupPoint();
      }
    }, [idOfferTemplate]);

    React.useEffect(() => {
      if (!paymentTypes) {
        setPaymentMethod(IPaymentTypes.OnSite);
      }

      let typesOfPayment = paymentTypes;
      if (!userHasBadge) {
        typesOfPayment = typesOfPayment.filter(
          (item: IPaymentTypes) => item !== IPaymentTypes.Badge
        );
      }

      const isBadgePaymentAvailable = typesOfPayment.some(
        (item: any) => item === IPaymentTypes.Badge
      );
      if(isBadgePaymentAvailable || typesOfPayment.length === 0) {
        setPaymentMethod(IPaymentTypes.Badge);
      }
      else if (typesOfPayment.includes(IPaymentTypes.OnSite)){
        setPaymentMethod(IPaymentTypes.OnSite);
        setIsOtherCardOpen(true);
      } else {
        setPaymentMethod(IPaymentTypes.Badge);
        setIsOtherCardOpen(true);
      }
    }, [paymentTypes]);

    useEffect(() => {
      if (isRefillFromCart && isEqual(products, cartCurrentInfo.products))
        return;
      if (offerSlot?.withdrawRange)
        dispatch(OffersActions.setSelectedOfferSlot(null));
      setQuotationError(undefined);
      setNoBalanceError(false);
      setQuotation(undefined);
      setLoading(false);
      setOfferSlots(undefined);
    }, [products]);

    useEffect(() => {
      if (!isRefillFromCart) dispatch(OffersActions.setSelectedOfferSlot(null));
      return () => {
        if (!isMobile && !isRefillFromCart) dispatch(OffersActions.initOrderItems());
      };
    }, []);

    const needToAddBadge =
      paymentMethod === PaymentMethod.Badge && !userHasBadge;

    const isConfirmationButtonDisabled =
      (!!quotation &&
        !(
          (offerSlot?.withdrawRange ||
            isValidTableNumber ||
            isInstantCC ||
            selectedPickupPoint) &&
          paymentMethod &&
          !checkEdenredFunds
        )) ||
      !isHeartMealsRuleValid ||
      (isSeatClick && !tableNumber) ||
      (!!quotation &&
        isTableService &&
        idCashSystem === CashSystem.CSI &&
        !tableNumber) ||
      (paymentMethod === PaymentMethod.Edenred && checkEdenredFunds) ||
      (paymentMethod === PaymentMethod.Badge && !!offerSlots && needToAddBadge);

    return (
      <Container
        testID={TestIDs.restaurantDetail.views.cartContainer}
        isMobile={isMobile}
      >
        <>
          {isMobile && (
            <GoBackContainerMobile
              testID={TestIDs.home.confirmationPage.actions.goBack}
              onPress={handleGoBack}
            >
              <Back />
              <GoBackText>{I18n.t("common.return")}</GoBackText>
            </GoBackContainerMobile>
          )}
          <Title isMobile={isMobile}>
            {I18n.t("restaurantDetail.cart.basket")}
          </Title>
          {products?.length ? (
            <>
              <ProductsInformations
                products={products}
                hasContainer={hasContainer}
                setHasContainer={setHasContainer}
              />
              <Separator />
              {quotation && (
                <>

                  <QuotationSummary
                    quotation={quotation}
                    paymentMethod={paymentMethod}
                    isCatCSI={isCatCSI}
                  />
                  <Separator />
                </>
              )}
              {isClickPickup && !!quotation ? (
                <>
                  <SectionTitle extraStyles="margin-bottom: 10px">
                    {I18n.t("restaurantDetail.cart.pickupPoint")}
                  </SectionTitle>
                  <SectionTitle extraStyles="text-transform: capitalize;">
                    {activeOrderableOfferDate}
                  </SectionTitle>
                  <Text>{selectedPointOfSale?.name}</Text>
                  {lastUsedPickupPoint && (
                    <>
                      <LastPickupPointTitle>
                        {I18n.t(
                          "restaurantDetail.cart.lastPickupPointSelected"
                        )}
                      </LastPickupPointTitle>
                      <PickupPoint
                        data={lastUsedPickupPoint}
                        margin={"bottom"}
                        isSelected={
                          lastUsedPickupPoint?.numericId ===
                          selectedPickupPoint?.numericId
                        }
                        onClick={(point) =>
                          lastUsedPickupPoint?.numericId !==
                            selectedPickupPoint?.numericId &&
                          setSelectedPickupPoint(point)
                        }
                      />
                    </>
                  )}
                  <SearchBar>
                    <SearchInput
                      value={searchPickupPoints}
                      onChangeText={setSearchPickupPoints}
                    />
                    <SearchIcon />
                  </SearchBar>
                  <PickupPointsList
                    noResults={
                      searchPickupPoints.trim().length >= 3 &&
                      pickupPoints?.length === 0
                    }
                    pickupPoints={pickupPoints}
                    onClick={setSelectedPickupPoint}
                    selectedPoint={selectedPickupPoint}
                  />
                  <Separator />
                  {!isSeatClick &&
                    orderVM.isInAdvanceOrder(
                      offerSlots?.[0]?.withdrawRange
                    ) && (
                      <Row>
                        <SmallDescription fullWidth>
                          {I18n.t(
                            "restaurantDetail.cart.orderInAdvanceMessage"
                          )}
                        </SmallDescription>
                      </Row>
                    )}
                </>
              ) : isSeatClick ? (
                <>
                  <TableNumber
                    tableNumber={tableNumber}
                    onChangeTableNumber={handleTableNumberChange}
                    validateWithTableService={isTableService}
                  />

                  <Separator />
                </>
              ) : (
                !isInstantCC &&
                (isSeatClick || isTableService
                  ? !!quotation && (
                      <>
                        <TableNumber
                          tableNumber={tableNumber}
                          onChangeTableNumber={handleTableNumberChange}
                          validateWithTableService={isTableService}
                        />
                        <Separator />
                      </>
                    )
                  : offerSlots && (
                      <>
                        <OfferSlots
                          offerSlots={offerSlots}
                          isRefillFromCart={
                            isRefillFromCart &&
                            isEqual(products, cartCurrentInfo.products)
                          }
                        />
                        <Separator />
                      </>
                    ))
              )}
              {!!quotation && selectedOffer?.enableComment &&
                <>
                  <SectionTitle>{I18n.t("restaurantDetail.cart.comment")}</SectionTitle>
                  <OrderComment comment={orderComment} onChange={setOrderComment} />
                  <Separator />
                </>
              }
              {(offerSlots || quotation) && (
                <>
                  <CartPayment
                    paymentMethod={paymentMethod}
                    handleEdenredPayment={handleEdenredPayment}
                    edenredBalance={edenredBalance}
                    setPaymentMethod={async (paymentMethod) => {
                        setPaymentMethod(paymentMethod);
                        await handleQuotationValues(paymentMethod as string);
                    }}
                    balance={balance}
                    paymentTypes={paymentTypes}
                    isOtherCardOpen={isOtherCardOpen}
                    setIsOtherCardOpen={setIsOtherCardOpen}
                  />
                  {isMobile && hasContainer && (
                    <SmallDescription marginBottom={12} fullWidth>
                      {I18n.t("restaurantDetail.container.infoMessage")}
                    </SmallDescription>
                  )}
                  <CGU />
                  <Separator />
                  {!isSeatClick &&
                    orderVM.isInAdvanceOrder(
                      offerSlots?.[0]?.withdrawRange
                    ) && (
                      <Row>
                        <SmallDescription fullWidth>
                          {I18n.t(
                            "restaurantDetail.cart.orderInAdvanceMessage"
                          )}
                        </SmallDescription>
                      </Row>
                    )}
                </>
              )}

              <Row>
                <ProductDescription bold>
                  {I18n.t("restaurantDetail.cart.total")}
                </ProductDescription>
                <ProductDescription
                  bold
                  testID={TestIDs.restaurantDetail.texts.totalPrice}
                >
                  {priceValue}€
                </ProductDescription>
              </Row>
              <CartErrors
                checkEdenredFunds={checkEdenredFunds}
                quotation={quotation}
                quotationError={quotationError}
                checkBadgeFunds={checkBadgeFunds}
                edenredBalance={edenredBalance}
                checkRechargeBadgeLater={checkRechargeBadgeLater}
                noBalanceError={noBalanceError}
              />
              <CartSubmitButton
                disabled={isConfirmationButtonDisabled || loading}
                onPress={onPress()}
                label={buttonLabel}
                loading={loading}
              />
            </>
          ) : (
            <View testID={TestIDs.restaurantDetail.views.emptyCart}>
              <Text>{I18n.t("restaurantDetail.cart.addProduct")}</Text>
              <Text isFaded>{I18n.t("restaurantDetail.cart.emptyCart")}</Text>
            </View>
          )}
        </>
      </Container>
    );
  }
);
