"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EPOINTAGE_SITES = void 0;
const client_1 = require("@apollo/client");
exports.GET_EPOINTAGE_SITES = client_1.gql `
query getEPointageSites ($search: String){
    list: getEPointageHoldings(search: $search) {
        id: siteId
        label: siteName
    }
}  
`;
