import {
  EpointageServicesResponse,
  GetEpointageServicesCommand,
  EpointageSchedulerResponse,
  GetEpointageSchedulerCommand,
  EpointageSchoolsResponse,
  GetEpointageSchoolsCommand,
  EpointageCommentResponse,
  AddUpdateEpointageSchedulerCommand,
  EpointageAddUpdateSchedulerResponse,
  AddUpdateEpointageCommentCommand,
  GetPdfExportCommand,
  GetEpointageSitesCommand,
  EpointageSitesResponse,
} from "@foodi/core";

import { ThunkResult, Action, createReducer } from "@redux";
import { IListItem } from "@modules";

export interface EpointageState {
  services: IListItem[];
  schools: IListItem[];
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_SERVICES: "Epointage/SET_SERVICES",
  SET_SCHOOLS: "Epointage/SET_SCHOOLS",
};

/*************  Reducer  ****************/

const initialState: EpointageState = {
  services: [],
  schools: [],
};

const Reduction = {
  setServices: (
    state: EpointageState,
    { payload: services }: Action<IListItem[]>
  ): EpointageState => ({
    ...state,
    services,
  }),
  setSchools: (
    state: EpointageState,
    { payload: schools }: Action<IListItem[]>
  ): EpointageState => ({
    ...state,
    schools,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_SERVICES]: Reduction.setServices,
  [ActionTypes.SET_SCHOOLS]: Reduction.setSchools,
});

const ActionCreators = {
  setServices: (services: IListItem[]): Action<IListItem[]> => ({
    type: ActionTypes.SET_SERVICES,
    payload: services,
  }),
  setSchools: (schools: IListItem[]): Action<IListItem[]> => ({
    type: ActionTypes.SET_SCHOOLS,
    payload: schools,
  }),
};

const ThunkActionCreators = {
  getEpointageServices: (
      params: GetEpointageServicesCommand
  ): ThunkResult<Promise<EpointageServicesResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getEpointageServices } = getDependencies();
      return getEpointageServices.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getEpointageScheduler: (
      params: GetEpointageSchedulerCommand
  ): ThunkResult<Promise<EpointageSchedulerResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getEpointageScheduler } = getDependencies();
      return getEpointageScheduler.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addEpointageScheduler: (
    request: AddUpdateEpointageSchedulerCommand
  ): ThunkResult<Promise<EpointageAddUpdateSchedulerResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { addEpointageScheduler } = getDependencies();
      return addEpointageScheduler.execute(request);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateEpointageScheduler: (
    params: AddUpdateEpointageSchedulerCommand
  ): ThunkResult<Promise<EpointageAddUpdateSchedulerResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { updateEpointageScheduler } = getDependencies();
      return updateEpointageScheduler.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getEpointageSchools: (
      params: GetEpointageSchoolsCommand
  ): ThunkResult<Promise<EpointageSchoolsResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getEpointageSchools } = getDependencies();
      return getEpointageSchools.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addEpointageComment: (
    params: AddUpdateEpointageCommentCommand
  ): ThunkResult<Promise<EpointageCommentResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { addEpointageComment } = getDependencies();
      return addEpointageComment.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateEpointageComment: (
    params: AddUpdateEpointageCommentCommand
  ): ThunkResult<Promise<EpointageCommentResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { updateEpointageComment } = getDependencies();
      return updateEpointageComment.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPdfExport: (
    params: GetPdfExportCommand
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPdfExport } = getDependencies();
      return getPdfExport.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getEpointageSites: (
    params: GetEpointageSitesCommand
  ): ThunkResult<Promise<EpointageSitesResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getEpointageSites } = getDependencies();
      return getEpointageSites.execute(params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default reducer;

export {
  ThunkActionCreators as EpointageThunks,
  reducer as EpointageReducer,
  ActionTypes as EpointageTypes,
  ActionCreators as EpointageActions,
};
