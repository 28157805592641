"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TRANSACTIONS = void 0;
const client_1 = require("@apollo/client");
exports.GET_TRANSACTIONS = client_1.gql `
	query getTransactions($before: Cursor, $after: Cursor, $first: Int, $last: Int) {
		getUser {
			id
			guests {
				edges {
					node {
						id
						transactions: transactionsOptimized(
							order: "-date"
							first: $first
							last: $last
							after: $after
							before: $before
						) {
							pageInfo {
								startCursor
								hasPreviousPage
								endCursor
								hasNextPage
							}
							edges {
								node {
									id
									date: validDate
									virtual
									pos {
										id
										name
										type
									}
									total {
										amount
										currency
									}
									refill {
										amount
										currency
									}
									oldBalance {
										amount
										currency
									}
									newBalance {
										amount
										currency
									}
									isCredit
								}
							}
						}
					}
				}
			}
		}
	}
`;
