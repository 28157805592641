import { ArticleFamily, OrderItem } from "@foodi/core";
import {
  ActiveOffer,
  getOfferTemplates_list_edges_node_OfferTemplate_nextOrderableOffer_offerItems,
  IOfferTemplate,
  OfferSlot,
  NextOrderableCCOffer,
  OfferTemplateWithdrawalType,
} from "@foodi/core";
import _ from "lodash";
import moment from "moment-timezone";
import { Dispatch } from "react";
import { I18n } from "react-redux-i18n";
import { IOrderableOffer, OffersThunks } from "../ducks";
import { Holding } from "@foodi/core/lib/domain/entities/Holding";
import { menuFamiliesRank, IImportationTypes, IMPORTATION_TYPES } from "@atomic";
import { TabsType } from "@modules/home/viewmodels/RestaurantCardViewModel";

export interface ISlots {
  initSlot: string;
  endSlot: string;
  withdrawRange: string;
}

export interface IProduct {
  id: string;
  label: string;
  main: boolean;
  idRecipe?: string | undefined;
  certifications?: [string];
}

export interface IArticle {
  id: string;
  idElement: number
}

export interface ILocalArticle {
  id: string;
  article: IArticle
}

export interface IOfferItem
  extends getOfferTemplates_list_edges_node_OfferTemplate_nextOrderableOffer_offerItems {
  inheritedDescription?: string;
  allergens?: string[];
  certifications?: string[];
  products?: IProduct[];
  localArticle: ILocalArticle;
  isFormulaComplete?: boolean;
}



const productFamiliesRank: string[] = [
  "formulas",
  "ingredients",
  "break",
  "starters",
  "soups",
  "dishes",
  "sandwiches",
  "salads",
  "sideDishes",
  "dairies",
  "desserts",
  "pastries",
  "fruits",
  "breads",
  "snacks",
  "beverages",
  "miscellaneous",
  "vegetables",
  "cheeses",
  "toTaste",
  "breakfast",
  "condimentTable",
];

const OfferTemplateTypeOrders: { [key in TabsType]: { [key in OfferTemplateWithdrawalType]: number } } = {
    [TabsType.SEE_ALL]: {
        [OfferTemplateWithdrawalType.TABLE_SERVICE]: 1,
        [OfferTemplateWithdrawalType.POS_CLICK_SERVE]: 1,
        [OfferTemplateWithdrawalType.POS_AT_SITE]: 1,
        [OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT]: 1,
        [OfferTemplateWithdrawalType.POS_TAKE_AWAY]: 2,
        [OfferTemplateWithdrawalType.CLICK_AND_PICK_UP]: 2,
        [OfferTemplateWithdrawalType.CONNECTED_LOCKERS]: 2,
    },
    [TabsType.EAT_IN]: {
        [OfferTemplateWithdrawalType.TABLE_SERVICE]: 1,
        [OfferTemplateWithdrawalType.POS_CLICK_SERVE]: 1,
        [OfferTemplateWithdrawalType.POS_AT_SITE]: 1,
        [OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT]: 1,
        [OfferTemplateWithdrawalType.POS_TAKE_AWAY]: 2,
        [OfferTemplateWithdrawalType.CLICK_AND_PICK_UP]: 2,
        [OfferTemplateWithdrawalType.CONNECTED_LOCKERS]: 2,
    },
    [TabsType.TAKE_OUT]: {
        [OfferTemplateWithdrawalType.TABLE_SERVICE]: 2,
        [OfferTemplateWithdrawalType.POS_CLICK_SERVE]: 2,
        [OfferTemplateWithdrawalType.POS_AT_SITE]: 2,
        [OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT]: 2,
        [OfferTemplateWithdrawalType.POS_TAKE_AWAY]: 1,
        [OfferTemplateWithdrawalType.CLICK_AND_PICK_UP]: 1,
        [OfferTemplateWithdrawalType.CONNECTED_LOCKERS]: 1,
    },
}

const OfferTemplateWithdrawalTypeOrders: { [key in OfferTemplateWithdrawalType]: number } = {
    [OfferTemplateWithdrawalType.TABLE_SERVICE]: 1,
    [OfferTemplateWithdrawalType.POS_CLICK_SERVE]: 2,
    [OfferTemplateWithdrawalType.POS_AT_SITE]: 3,
    [OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT]: 4,
    [OfferTemplateWithdrawalType.POS_TAKE_AWAY]: 5,
    [OfferTemplateWithdrawalType.CLICK_AND_PICK_UP]: 6,
    [OfferTemplateWithdrawalType.CONNECTED_LOCKERS]: 7,
}

export class OfferViewModel {
  constructor(private dispatch?: Dispatch<any>) {}
  public readonly BOOKING_CARD_WIDTH_WITHGAP = 187;
  public readonly OFFER_CARD_WIDTH = 320;
  public readonly OFFER_CARD_WIDTH_WITHGAP = 325;
  public readonly OFFER_CARD_HEIGHT = 140;
  public readonly OFFER_CARD_GAP = 5;
  public readonly MIN_OFFERCARD_DISPLAY = this.OFFER_CARD_WIDTH * 2 + this.OFFER_CARD_GAP *2;


  hasAvailableOffer = (offer: IOfferTemplate): boolean => {
    const { nextOrderableOffer, nextOrderableOffers } = offer;
    const haveAvailableItems = !!nextOrderableOffer?.offerItems.some(({ quantityRemaining }) => quantityRemaining > 0);
    const hasAvailableItemsFormula = !!nextOrderableOffer?.offerItemsFormula?.some(({isFormulaComplete}) => isFormulaComplete);
    const isMealHeartRuleFulfilled = !!nextOrderableOffer?.isMealHeartRuleFullfilled;

    return !!nextOrderableOffer?.available
      && isMealHeartRuleFulfilled
      && (haveAvailableItems || hasAvailableItemsFormula)
      && !!nextOrderableOffer?.mealHeartOrderAvailable
      && !this.areNextOffersSlotsFullyBooked(offer)
  };

  isActiveOfferAvailable = (activeOffer: ActiveOffer): boolean => {
    if (activeOffer === null) {
      return false;
    }

    const currentDay = moment();
    const [orderStartDate, orderEndDate] = activeOffer?.orderRange?.split("/");
    const haveAvailableItems = !!activeOffer?.offerItems?.some(({ quantityRemaining }) => quantityRemaining > 0);
    const hasAvailableItemsFormula = !!activeOffer?.offerItemsFormula?.some(({isFormulaComplete}) => isFormulaComplete);

    return (
      activeOffer?.published
      && (haveAvailableItems || hasAvailableItemsFormula)
      && currentDay.isBetween(moment(orderStartDate), moment(orderEndDate))
    );
  };

  isOfferAvailable = (offer: IOfferTemplate): boolean => {
    if (offer === null) {
      return false;
    }
    const { nextOrderableOffer } = offer;

    if (!nextOrderableOffer) return false;

    const haveAvailableItems = !!nextOrderableOffer?.offerItems.some(({ quantityRemaining }) => quantityRemaining > 0);

    const currentDay = moment();
    const [orderStartDate, orderEndDate] = nextOrderableOffer?.withdrawRange?.split("/");

    return !!nextOrderableOffer?.available
      && haveAvailableItems
      && !!nextOrderableOffer?.mealHeartOrderAvailable
      && !this.areNextOffersSlotsFullyBooked(offer)
      && currentDay.isBetween(moment(orderStartDate), moment(orderEndDate))
  };

  areNextOffersSlotsFullyBooked = ({
    nextOrderableOffers,
  }: IOfferTemplate): boolean =>
    !!nextOrderableOffers &&
    !nextOrderableOffers
      .some(
        (eachNextOrderableOffer: any) => (
          eachNextOrderableOffer.fullyBooked === false
        ));

  isOrderRangeValid = (orderRange: string): boolean => {
    const [beginDate, endDate] = orderRange.split('/');
    const currentDate = new Date().toISOString();

    return (
      currentDate >= new Date(beginDate).toISOString() &&
      currentDate <= new Date(endDate).toISOString()
    );
  };
  isActiveOfferReady = (activeOffer: ActiveOffer): boolean => {
    if (activeOffer === null) {
      return false;
    }
    const currentDay = moment();
    const [orderStartDate] = activeOffer?.orderRange?.split("/");

    return (
      !!activeOffer?.published && currentDay.isBefore(moment(orderStartDate))
    );
  };

  sortOffers = (offers: IOfferTemplate[], priorityType: TabsType): IOfferTemplate[] => {
      return offers
          .sort((offer1, offer2) => {
              const nextOrderableOffer1 = (offer1?.nextOrderableOffers as NextOrderableCCOffer[])?.find((offer: NextOrderableCCOffer) => offer.published) || (offer1?.nextOrderableOffers?.[0]) as any;
              const nextOrderableOffer2 = (offer2?.nextOrderableOffers as NextOrderableCCOffer[])?.find((offer: NextOrderableCCOffer) => offer.published) || (offer2?.nextOrderableOffers?.[0]) as any;
              if(!nextOrderableOffer1) return -1;
              if(!nextOrderableOffer2) return 1;
              const offer1Ready = this.isActiveOfferReady(nextOrderableOffer1)
              const offer2Ready = this.isActiveOfferReady(nextOrderableOffer2)
              const offer1Available = this.isActiveOfferAvailable(nextOrderableOffer1)
              const offer2Available = this.isActiveOfferAvailable(nextOrderableOffer2)
              if(offer1Ready && offer2Available) return 1;
              if(offer1Available && offer2Ready) return -1;
              if(OfferTemplateTypeOrders[priorityType][offer1?.withdrawalType as OfferTemplateWithdrawalType] !== OfferTemplateTypeOrders[priorityType][offer2?.withdrawalType as OfferTemplateWithdrawalType]) {
                  return OfferTemplateTypeOrders[priorityType][offer1?.withdrawalType as OfferTemplateWithdrawalType] - OfferTemplateTypeOrders[priorityType][offer2?.withdrawalType as OfferTemplateWithdrawalType];
              }

              const [offer1OrderStartDate] = nextOrderableOffer1?.orderRange?.split('/');
              const [offer2OrderStartDate] = nextOrderableOffer2?.orderRange?.split('/');
              if(offer1Available){
                  if (offer1OrderStartDate != offer2OrderStartDate) {
                      if (moment(offer2OrderStartDate).isAfter(moment(offer1OrderStartDate))) return 1;
                      return -1
                  }
              }
              if(offer1Ready) {
                  if (offer1OrderStartDate != offer2OrderStartDate) {
                      if (moment(offer2OrderStartDate).isAfter(moment(offer1OrderStartDate))) return -1;
                      return 1
                  }
              }
              return 0;
          });
  }

  sortFamilyItemsByName = (offers: IOfferItem[]): IOfferItem[] => {
    return offers.sort((offer1, offer2) => {
        return offer1.inheritedLabel.localeCompare(offer2.inheritedLabel);
      })
  }

  getDateDescription = (
    selectedDay?: number,
    userLanguage?: string
  ): string => {
    const _selectedDay = selectedDay ?? 0;
    return `${_.upperFirst(
      moment()
        .add(_selectedDay, "days")
        .locale(userLanguage || "fr")
        .format("dddd")
    )} ${moment().add(_selectedDay, "days").format("DD/MM")}`;
  };

  getWithdrawDateFromOfferSlot = (offer?: ISlots | null): string => {
    return offer?.withdrawRange.split("/")?.[1].split("T")?.[0] || "";
  };

  getHourFromDefaultOrderRange = (time?: string | null ): string => {
    const timeWithoutLocal =  time ? time?.slice(2,10).replace('M', '') : "00H00";
    if (timeWithoutLocal === "0S") {
      return "00:00";
    }

    const hh = timeWithoutLocal.split('H')?.[0]?.padStart(2, '0');
    const mm = timeWithoutLocal.split('H')?.[1]?.padStart(2, '0');
    return `${hh}:${mm}`;
  };

  getHourFromOrderRange = (time: string ): string => {
    const [startDate, endDate] = time.split("/");
    const startTime = new Date(startDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    const endTime = new Date(endDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    return `${startTime} - ${endTime}`;
  };

  checkIfDayHasOffer = (
    dayToCheck: string,
    nextOrderableOffers: IOrderableOffer[] | null
  ): boolean => {
    const currentDay = moment().tz('Europe/Paris');
    const formatedCurrentDay = currentDay.format("YYYY-MM-DD");

    const haveOffer =
      (nextOrderableOffers &&
        !!nextOrderableOffers.find(
          ({ withdrawRange, published, orderRange }) => {
            const [startDate, endDate] = withdrawRange.split("/");
            const [orderStartDate, orderEndDate] = orderRange.split("/");
            const endDateWithoutTime = endDate?.split("T")?.[0];
            const convertedOrderStartDate = moment(orderStartDate).tz('Europe/Paris');

            // for the current day we need to check if the offer is already expired or not
            return formatedCurrentDay === dayToCheck && dayToCheck === moment(startDate).tz('Europe/Paris').format('YYYY-MM-DD')
              ? currentDay.isBetween(convertedOrderStartDate, moment(endDate).tz('Europe/Paris')) &&
                  published
              : endDateWithoutTime === dayToCheck &&
                  currentDay.isBetween(
                    convertedOrderStartDate,
                    moment(orderEndDate).tz('Europe/Paris')
                  ) &&
                  published;
          }
        )) ||
      false;

    return !haveOffer;
  };

  getOfferDayIndex = (
    dayToFind: string,
    nextOrderableOffers: IOrderableOffer[] | null
  ): number => {
    const haveOffer =
      nextOrderableOffers &&
      nextOrderableOffers.findIndex(
        ({withdrawRange, orderRange}) =>
        { 
          // in order to correctly compare all the dates we need to convert the withdrawRange of the Offer Template
          // to the current timezone in Paris
          // Otherwise it will compare to the server time which might be different from the time in Paris
          // there were some cases with Offers opening at midnight where they were being display for the previous day
          //    ex: an offer for the 05/10 was being display on the 04/10 
          const currentDay = moment().tz('Europe/Paris');
          const formatedCurrentDay = currentDay.format('YYYY-MM-DD');
          const [_startDate, endDate] = withdrawRange.split("/");
          const [orderStartDate, _orderEndDate] = orderRange.split("/");
          const convertedOrderStartDate = moment(orderStartDate).tz('Europe/Paris');
          const convertedEndDate = moment(endDate).tz('Europe/Paris');
          const formatedEndDate = convertedEndDate.format('YYYY-MM-DD')

          // when searching for the current day we also need to check if the current day it between the 
          return dayToFind === formatedCurrentDay && formatedCurrentDay === formatedEndDate ? (
            currentDay.isBetween(convertedOrderStartDate, convertedEndDate)
          ) : formatedEndDate === dayToFind;
      });

    return haveOffer || 0;
  };

  async getOffer(idOffer: string): Promise<ActiveOffer> {
    //@ts-ignore
    const { offer } = await this.dispatch(OffersThunks.getOffer({ idOffer }));
    return offer;
  }

  getCurrentSlots = (offerSlots: Omit<OfferSlot, "__typename">[]): ISlots[] => {
    return offerSlots
      .filter((o) => {
        const endSlot = o?.withdrawRange?.split("/")?.[1];
        return moment().isSameOrBefore(endSlot);
      })
      ?.map((o) => {
        const slots = o?.withdrawRange?.split("/");

        const initSlot = moment(slots?.[0]).format("HH:mm");
        const endSlot = moment(slots?.[1]).format("HH:mm");

        return {
          initSlot,
          endSlot,
          withdrawRange: o?.withdrawRange,
          selectableAt: o?.selectableAt,
          numOrders: o?.numOrders,
        };
      });
  };

  getWarningMessage = (
    offerSlot: ISlots | null,
    isSelected?: number,
    changeSelection?: boolean,
    fullyBooked?: boolean,
    published?: boolean,
    hasSlots?: boolean,
    bookingForDifferentPos?: boolean,
    isBookingSelected?: boolean
  ) => {
    return !isBookingSelected
      ? isSelected !== undefined
        ? `${I18n.t("restaurantDetail.cart.youChose")}: ${
            offerSlot?.initSlot
          }-${offerSlot?.endSlot}`
        : I18n.t("restaurantDetail.cart.chooseSlot")
      : published
      ? I18n.t("restaurantDetail.bookingUnavailable")
      : bookingForDifferentPos
      ? I18n.t("restaurantDetail.bookingForAnotherPos")
      : !hasSlots
      ? I18n.t("restaurantDetail.bookingExpired")
      : fullyBooked
      ? I18n.t("restaurantDetail.fullyBooked")
      : offerSlot && changeSelection === false
      ? I18n.t("restaurantDetail.reservationRegistered", {
          slot: `${offerSlot?.initSlot}/${offerSlot?.endSlot}`,
        })
      : isSelected !== undefined
      ? `${I18n.t("restaurantDetail.cart.youChose")}: ${offerSlot?.initSlot}-${
          offerSlot?.endSlot
        }`
      : I18n.t("restaurantDetail.cart.chooseSlot");
  };

  static getAllergensWarningMessage = (
    menuType?: IImportationTypes
  ): string => {
    switch (menuType) {
      case IMPORTATION_TYPES.WINAPRO:
        return I18n.t(
          "restaurantDetail.product.allergensWarningMessages.winapro"
        );
      default:
        return I18n.t(
          "restaurantDetail.product.allergensWarningMessages.oscar"
        );
    }
  };

  getGroupedProducts = (offerItems: IOfferItem[] | undefined) => {
    if (!offerItems) return null;
    return offerItems.reduce<Record<string, IOfferItem[]>>(
      (_offerItems, item) => {
        _offerItems[`${item.inheritedFamily}`] = [
          ...(_offerItems[`${item.inheritedFamily}`] ?? []),
          item,
        ];
        return _offerItems;
      },
      {}
    );
  };

  static sortFamiliesListMenu = (
    translatedEntries: any,
    holding: Holding,
    isSimpleArray?: boolean
  ) => {
    return translatedEntries.sort(
      (a: any, b: any) =>
        menuFamiliesRank[holding.importationType as IImportationTypes].indexOf(
          isSimpleArray ? a : a[0]
        ) -
        menuFamiliesRank[holding.importationType as IImportationTypes].indexOf(
          isSimpleArray ? b : b[0]
        )
    );
  };

  static sortFamiliesList = (
    translatedEntries: any,
    isSimpleArray?: boolean
  ) => {
    return translatedEntries.sort(
      (a: any, b: any) =>
        productFamiliesRank.indexOf(isSimpleArray ? a : a[0]) -
        productFamiliesRank.indexOf(isSimpleArray ? b : b[0])
    );
  };

  sortFamilyByName = (
    groupedProducts: Record<string, IOfferItem[]>,
    isMenu: boolean,
    holding: Holding
  ) => {
    const entries = Object.entries(groupedProducts);

    const translatedGroupedProducts = _.chain(
      entries?.map((p: [string, IOfferItem[]]) => {
        const cleanerLabel = OfferViewModel.getLabelFromProductFamily(p[0]);
        return { cleanerLabel, values: p[1] };
      })
    )
      .groupBy("cleanerLabel")
      .mapValues((v: any) => _.chain(v).map("values").flattenDeep().value())
      .value();

    const translatedEntries = Object.entries(translatedGroupedProducts);

    isMenu
      ? OfferViewModel.sortFamiliesListMenu(translatedEntries, holding)
      : OfferViewModel.sortFamiliesList(translatedEntries);

    return translatedEntries;
  };

  getRealFamilyName = (items: IOfferItem[]) => {
    return items?.[0]?.inheritedFamily || "";
  };

  getProductAmount = (_amount: string | null): any => {
    const amount = _amount || "0,00";
    return parseFloat(amount?.replace(",", ".")).toFixed(2);
  };

  isOnSiteOffer = (withdrawalType: string): boolean => {
    return [OfferTemplateWithdrawalType.POS_CLICK_SERVE,
      OfferTemplateWithdrawalType.POS_AT_SITE,
      OfferTemplateWithdrawalType.TABLE_SERVICE,
      OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT]
      .some((offerTemplatewithdrawalType)=>offerTemplatewithdrawalType === withdrawalType)};

  getContainerAmount = (_amount: string | null): any => {
    const amount = _amount || "0,00";
    return parseFloat(amount?.replace(",", ".")).toFixed(2);
  };

  getOfferDescription = (offer: IOfferItem, menuType?: IImportationTypes) => {
    const description = (offer as any)?.description;

    if (!!description) {
      return description;
    } else if (menuType === IMPORTATION_TYPES.WINAPRO) {
      return null;
    }

    if (offer?.inheritedDescription) {
      return offer?.inheritedDescription;
    }

    if (offer.products && offer.products?.length < 2 ) {
      return null;
    }

    return (
      offer.products
        ?.map((product) =>
          _.capitalize(_.trimEnd(product.label.toLowerCase(), "."))
        )
        .join(" / ")
    );
  };

  computeTotalPrice = (products: OrderItem[]) => {
    let totalContainerPrice: number = 0;

    const totalPrice = products.reduce((acc, current) => {
      const amount = this.getProductAmount(current.priceWhenAdded.amount);

      const containerAmount = this.getContainerAmount(
        current.containerPriceWhenAdded
          ? current.containerPriceWhenAdded.amount
          : ""
      );

      totalContainerPrice += containerAmount * current.quantity;

      return acc + amount * current.quantity;
    }, 0);

    return (totalPrice + totalContainerPrice).toFixed(2).replace(".", ",");
  };

  static getLabelFromProductFamily = (family: string) => {
    const adjustedLabel = family?.trim?.();
    return _.get(
      {
        FORMULA: "formulas",
        INGREDIENTS: "ingredients",
        STARTERS: "starters",
        SOUPS: "soups",
        DISHES: "dishes",
        SANDWICHES: "sandwiches",
        SALADS: "salads",
        "SIDE DISHES": "sideDishes",
        DAIRIES: "dairies",
        PASTRIES: "pastries",
        FRUITS: "fruits",
        BREADS: "breads",
        SNACKS: "snacks",
        BEVERAGES: "beverages",
        MISCELLANEOUS: "miscellaneous",
        BREAK: "break",
        CHEESE: "cheeses",
        BREAKFAST: "breakfast",
        // product families given by Click and Collect
        STARTER: "starters",
        SOUP: "soups",
        DISH: "dishes",
        SANDWICH: "sandwiches",
        SALAD: "salads",
        SIDE_DISH: "sideDishes",
        DAIRY: "dairies",
        DESSERT: "desserts",
        PASTRY: "pastries",
        FRUIT: "fruits",
        BREAD: "breads",
        SNACKING: "snacks",
        BEVERAGE: "beverages",
        // product families given by Booking (Oscar)
        "SALAD BAR AU KILO": "salads",
        "PRODUITS LAITIERS": "dairies",
        "DESSERTS BAR AU KILO": "dessertsBar",
        PAIN: "breads",
        BOISSONS: "beverages",
        DIVERS: "miscellaneous",
        PAUSE: "break",
        LEGUMES: "vegetables",
        FROMAGES: "cheeses",
        GOUTER: "toTaste",
        "PLATS CHAUDS": "menuHotMeals",
        "HORS D'OEUVRES": "menuStarters",
        DESSERTS: "menuDesserts",
        ENTREES: "menuStarters",
        ACCOMP: "menuAccompaniment",
        ACCOMPAGNEMENTS: "menuAccompaniment",
        PLAT: "menuHotMeals",
        ENTREE: "menuStarters",
        COMPLEMENT: "menuCompliments",
        FROMAGE: "menuCheese",
        "PETIT DEJEUNER": "breakfast",
        "TABLE A CONDIMENT": "condimentTable",
      },
      adjustedLabel,
      adjustedLabel
    );
  };

  hasContainers = (offerItems: IOfferItem[] | undefined) =>
    !!offerItems && offerItems.some(({ container }) => !!container);

  convertFormulaItemsToProducts = (formulaItems?: any[]) =>{
    if (!formulaItems?.length) return [];

    const divideFormulaItems = (formulaItems: any[]) => {
      return formulaItems.reduce(([formulaProducts, formulaSubProducts], item) => {
          return typeof item.stepNumber !== 'number'
            ? [[...formulaProducts, item], formulaSubProducts]
            : [formulaProducts, [...formulaSubProducts, item]];
        },
        [[], []]
      );
    }

    const [formulaProducts, formulaSubProducts] = divideFormulaItems(formulaItems);

    const items = formulaProducts
      .map((item: any) => ({
        __typename: "OfferItem",
        articleCertifications: [],
        articleTags: [],
        baking: item.baking,
        container: item.container,
        id: item.id,
        idFormulaTemplate: item.formulaTemplate.id,
        inheritedDescription: item.formulaTemplate.description,
        inheritedFamily: ArticleFamily.FORMULA,
        inheritedImage: item.formulaTemplate.image.path,
        inheritedLabel: item.formulaTemplate.name,
        inheritedPrice: item.formulaTemplate.price,
        quantityOverall: item.quantityOverall,
        quantityPurchasable: item.quantityPurchasable,
        quantityRemaining: item.quantityRemaining,
        isFormulaComplete: item.isFormulaComplete,
        steps: item.steps,
        subProducts: formulaSubProducts.filter((subItem: any) => subItem.idOfferItemFormulaParent === item.numericId),
      }));
      return items;
    }
  };
