"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateMFAConfig = void 0;
class UpdateMFAConfig {
    constructor(profileService) {
        this.profileService = profileService;
    }
    execute(request) {
        return this.profileService.updateMFAConfig(request);
    }
}
exports.UpdateMFAConfig = UpdateMFAConfig;
