"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PROFILE_SECTIONS_DATA = void 0;
const client_1 = require("@apollo/client");
exports.GET_PROFILE_SECTIONS_DATA = client_1.gql `
  query getProfileSectionsData {
    getUser {
      id
      email
      userOptins {
        optin {
          code
        }
        optinOptions {
          minBalance
        }
      }
      virtualTicket
      mfaEnabled
    }
  }
`;
