"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEpointageRepository = void 0;
const __1 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
const EPointageSchedulerMapper_1 = require("../mappers/EPointageSchedulerMapper");
const EpointageSchoolsMapper_1 = require("../mappers/EpointageSchoolsMapper");
class FEpointageRepository {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getEpointageServices(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const epointageServicesMapper = new mappers_1.EpointageServicesMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_EPOINTAGE_SERVICES,
                    variables,
                });
                return Promise.resolve(epointageServicesMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.getEpointageServicesError("GET_EPOINTAGE_SERVICES_REQUEST_FAILED", error));
            }
        });
    }
    getEpointageScheduler(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const epointageSchedulerMapper = new EPointageSchedulerMapper_1.EpointageSchedulerMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_EPOINTAGE_SCHEDULER,
                    fetchPolicy: "network-only",
                    variables,
                });
                return Promise.resolve(epointageSchedulerMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.getEpointageSchedulerError("GET_EPOINTAGE_SCHEDULER_REQUEST_FAILED", error));
            }
        });
    }
    addEpointageScheduler(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const ePointageAddUpdateSchedulerMapper = new mappers_1.EPointageAddUpdateSchedulerMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.ADD_EPOINTAGE_SCHEDULER,
                    refetchQueries: ["getEpointageScheduler"],
                    variables: input,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(ePointageAddUpdateSchedulerMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.addEpointageSchedulerError("ADD_EPOINTAGE_SCHEDULER_REQUEST_FAILED", error));
            }
        });
    }
    updateEpointageScheduler(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const ePointageAddUpdateSchedulerMapper = new mappers_1.EPointageAddUpdateSchedulerMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_EPOINTAGE_SCHEDULER,
                    refetchQueries: ["getEpointageScheduler"],
                    variables: input,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(ePointageAddUpdateSchedulerMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.updateEpointageSchedulerError("UPDATE_EPOINTAGE_SCHEDULER_REQUEST_FAILED", error));
            }
        });
    }
    getEpointageSchools(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const epointageSchoolsMapper = new EpointageSchoolsMapper_1.EpointageSchoolsMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_EPOINTAGE_SCHOOLS,
                    variables,
                });
                return Promise.resolve(epointageSchoolsMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.getEpointageSchoolsError("GET_EPOINTAGE_SCHOOLS_REQUEST_FAILED", error));
            }
        });
    }
    addEpointageComment(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const epointageCommentMapper = new mappers_1.EpointageCommentMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.ADD_EPOINTAGE_COMMENT,
                    variables,
                });
                return Promise.resolve(epointageCommentMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.addEpointageCommentError("ADD_EPOINTAGE_COMMENT_REQUEST_FAILED", error));
            }
        });
    }
    updateEpointageComment(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const epointageCommentMapper = new mappers_1.EpointageCommentMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_EPOINTAGE_COMMENT,
                    variables,
                });
                return Promise.resolve(epointageCommentMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.updateEpointageCommentError("UPDATE_EPOINTAGE_COMMENT_REQUEST_FAILED", error));
            }
        });
    }
    getPdfExport(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const pdfExportMapper = new mappers_1.PdfExportMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_PDF_EXPORT,
                    variables,
                });
                return Promise.resolve(pdfExportMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.getEpointagePdfExportError("GET_EPOINTAGE_PDF_EXPORT_REQUEST_FAILED", error));
            }
        });
    }
    getEpointageSites(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const epointageSitesMapper = new mappers_1.EpointageSitesMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_EPOINTAGE_SITES,
                    variables,
                });
                return Promise.resolve(epointageSitesMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.EpointageErrors.getEpointageSitesError("GET_EPOINTAGE_SITES_REQUEST_FAILED", error));
            }
        });
    }
}
exports.FEpointageRepository = FEpointageRepository;
