import { Colors } from "@constants";
import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Title13, Title14, Title16 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { EPointageCheckbox } from "../EPointageCheckbox";
import {
  AddUpdateEpointageSchedulerCommand,
  ComputedStudent,
} from "@foodi/core";
import { useDispatch } from "react-redux";
import {
  EpointageThunks,
  ScheduleTimeline,
  SchedulerViewModel,
  Schedules,
  SelectedDateTimeline,
} from "@modules";
import { User } from "@assets";
import moment from "moment";
import { LoaderActions } from "@redux";
import { toast } from "react-toastify";

interface IProps {
  schedule: Schedules;
  prevschedule: Schedules;
  scheduleIndex: number;
  updateTotalChecks: (total: number) => void;
  selectedDate: string;
  userId: string;
  siteId: string;
  handleSchedulerInfo: () => Promise<void>;
  haveWritePermission: boolean;
}

export const EPointageClassDetailSchedule: React.FC<IProps> = ({
  schedule,
  prevschedule,
  scheduleIndex,
  updateTotalChecks,
  selectedDate,
  userId,
  handleSchedulerInfo,
  haveWritePermission,
  siteId
}) => {
  const dispatch = useDispatch();
  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );

  const studentList = schedulerVM.getStudentsFromSchedule(schedule);
  const prevStudentList = schedulerVM.getStudentsFromSchedule(prevschedule);

  const [students, setStudents] = useState<ComputedStudent[]>([]);
  const [allCheck, setAllCheck] = useState<boolean>(false);
  const [isPreckNeeded, setIsPreckNeeded] = useState<boolean>(false);

  const countPresences =
    schedule.schedulerTimeline !== ScheduleTimeline.FUTURE_SCHEDULE
      ? schedulerVM.getCountPresences(students)
      : 0;
  const currentScheduleStyle = schedule.currentStyleSchedule;
  const havePointer =
    haveWritePermission &&
    schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE;

  React.useEffect(() => {
    const isPreckNeeded = schedulerVM.checkIfNeedsPreCheck(studentList);
    setIsPreckNeeded(isPreckNeeded);

    const studentsListToUse = schedulerVM.studentsListToUse(
      scheduleIndex,
      studentList,
      prevStudentList,
      schedule.schedulerTimeline
    );

    setStudents(studentsListToUse);
    schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE &&
      updateTotalChecks(schedulerVM.getCountPresences(studentsListToUse));
  }, []);

  const handlePress = (id: number, familyId: number) => {
    if (!haveWritePermission) return;
    if (schedule.schedulerTimeline !== ScheduleTimeline.CURRENT_SCHEDULE)
      return;
    const updatedStudent = students.map((student: ComputedStudent) => {
      if (student.id === id && student.familyId === familyId) {
        return { ...student, presence: !student.presence };
      }
      return student;
    });
    setAllCheck(false);
    setStudents(updatedStudent);
    updateTotalChecks(schedulerVM.getCountPresences(updatedStudent));
    setIsPreckNeeded(true);
  };

  const handleCheckAll = () => {
    if (!haveWritePermission) return;
    setAllCheck(!allCheck);
    const updatedStudent = students.map((student: ComputedStudent) => {
      return { ...student, presence: !allCheck };
    });
    setStudents(updatedStudent);
    setIsPreckNeeded(true);
    updateTotalChecks(schedulerVM.getCountPresences(updatedStudent));
  };

  const handleSubmitChanges = async () => {
    if (!haveWritePermission) return;

    const isDeadlinePassed = schedulerVM.checkIfDeadlinePassed(
      schedule.deadline
    );

    const isCurrentDay = schedulerVM.checkSelectedDateTimeline(selectedDate);

    if (
      isDeadlinePassed &&
      isCurrentDay === SelectedDateTimeline.CURRENT_DATE
    ) {
      await handleSchedulerInfo();
      toast.error(I18n.t("epointage.homepage.classroom.registrationOverDeadline"));
      return;
    }

    dispatch(LoaderActions.setLoading(true));
    const isEdit = !schedulerVM.checkIfNeedsPreCheck(studentList);
    const inputFormatedStudents = schedulerVM.getStudentValuesForMutation(
      students
    );
    const updateUserId = parseInt(userId);

    const input: AddUpdateEpointageSchedulerCommand = {
      siteId,
      etaId: students[0].schoolId,
      claId: students[0].classroomId,
      scheduleId: schedule.id,
      isInvoice: schedule.isInvoice,
      scheduleNumberDay: parseInt(selectedDate.split("-")[2]),
      scheduleDeadline: schedule.deadline,
      checkingDay: selectedDate,
      updateUser: isNaN(updateUserId) ? 999 : updateUserId,
      updateDate: moment().format("YYYY-MM-DD"),
      students: inputFormatedStudents,
    };

    try {
      isEdit
        ? await dispatch(EpointageThunks.updateEpointageScheduler(input))
        : await dispatch(EpointageThunks.addEpointageScheduler(input));
    } catch (error) {
    } finally {
      handleSchedulerInfo();
    }
  };

  const headerCheckboxLabel = allCheck
    ? I18n.t("epointage.homepage.classroom.unselectAll")
    : I18n.t("epointage.homepage.classroom.selectAll");

  const footerLabel =
    schedule.schedulerTimeline === ScheduleTimeline.PAST_SCHEDULE
      ? I18n.t("epointage.homepage.classroom.ended")
      : I18n.t("epointage.homepage.classroom.unavailable");

  return (
    <View
      style={[
        styles.scheduleColumn,
        { backgroundColor: currentScheduleStyle.scheduleBackground },
      ]}
    >
      <View style={styles.scheduleColumnHeader}>
        <Title14 bold>{schedule.deadline}</Title14>
        {schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE && (
          <View style={styles.headerCheckboxWrapper}>
            <EPointageCheckbox
              checked={allCheck}
              onPress={handleCheckAll}
              checkboxStyle={currentScheduleStyle.checkboxStyle}
              isPreckNeeded={isPreckNeeded}
              havePointer={havePointer}
            />
            <View style={styles.headerCheckboxLabel}>
              <Title13 color={Colors.black}>{headerCheckboxLabel}</Title13>
            </View>
          </View>
        )}
      </View>
      <View>
        {students.map((student: any, index: number) => (
          <View key={index} style={[styles.row, { alignItems: "center" }]}>
            <EPointageCheckbox
              checked={student.presence}
              onPress={() => handlePress(student.id, student.familyId)}
              checkboxStyle={currentScheduleStyle.checkboxStyle}
              showCheck={
                schedule.schedulerTimeline !== ScheduleTimeline.FUTURE_SCHEDULE
              }
              isPreckNeeded={
                isPreckNeeded &&
                schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE
              }
              havePointer={havePointer}
            />
          </View>
        ))}
      </View>
      <View style={styles.scheduleColumnFooter}>
        <View style={styles.sectionWrapper}>
          <User
            color={
              isPreckNeeded &&
              schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE
                ? Colors.epointageOrange
                : currentScheduleStyle.deadlineColor
            }
          />
          <Title16
            bold
            color={
              isPreckNeeded &&
              schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE
                ? Colors.epointageOrange
                : currentScheduleStyle.deadlineColor
            }
          >
            {countPresences}
          </Title16>
        </View>
        {schedule.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE ? (
          <>
            {haveWritePermission ? (
              <TouchableOpacity onPress={handleSubmitChanges}>
                <View style={styles.button}>
                  <Title16 bold>
                    {I18n.t("epointage.homepage.classroom.register")}
                  </Title16>
                </View>
              </TouchableOpacity>
            ) : (
              <View style={styles.footerLabel}>
                <Title16 color={Colors.foodiBlack}>
                  {I18n.t("epointage.homepage.classroom.inProgress")}
                </Title16>
              </View>
            )}
            {isPreckNeeded && haveWritePermission && (
              <View style={styles.needValidateWrapper}>
                <Title16 color={Colors.epointageOrange}>
                  {I18n.t("epointage.homepage.classroom.needRegistration")}
                </Title16>
              </View>
            )}
          </>
        ) : (
          <View style={styles.footerLabel}>
            <Title16 color={Colors.foodiBlack}>{footerLabel}</Title16>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  scheduleColumn: {
    flex: 1,
    textAlign: "center",
    flexDirection: "column",
    paddingVertical: 14,
  },
  scheduleColumnHeader: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    borderBottomColor: Colors.disabledBackground,
    borderBottomWidth: 1,
    height: 85,
  },
  row: {
    height: 45,
    justifyContent: "center",
    borderBottomColor: Colors.disabledBackground,
    borderBottomWidth: 1,
  },
  scheduleColumnFooter: {
    padding: 15,
    alignItems: "center",
  },
  button: {
    width: 135,
    marginTop: 10,
    height: 47,
    justifyContent: "center",
    backgroundColor: Colors.foodiDefault,
    textAlign: "center",
    borderRadius: 8,
  },
  footerLabel: {
    width: 135,
    height: 57,
    justifyContent: "center",
    textAlign: "center",
  },
  sectionWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 10,
  },
  headerCheckboxWrapper: {
    marginTop: 20,
    flexDirection: "row",
    textAlign: "left",
    marginBottom: 10,
  },
  headerCheckboxLabel: {
    width: 100,
    position: "absolute",
    left: 30,
  },
  needValidateWrapper: {
    marginTop: 10,
    marginHorizontal: 10,
  },
});
