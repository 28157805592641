import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Title16, Title22, Title36 } from "@stylesheets";
import { TestIDs } from "@utils";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import { TransactionsList, TransactionTabs, ValidButton } from "@atomic";
import { ITransaction } from "@foodi/core";

import { ITransactionType, TransactionsThunks, TransactionsViewModel } from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, LoaderActions } from "@redux";
import { useDevices } from "@hooks";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
	navigation: NavigationProp<any>;
}

const MS_YEAR = 1000 * 60 * 60 * 24 * 365;

export const TransactionsTemplate: React.FC<IProps> = React.memo(({ navigation }) => {
	const transactionPerPage = 20;
	const transactionVM = new TransactionsViewModel();
	const dispatch = useDispatch();
	const [tabs, setTabs] = useState<ITransactionType[]>([]);
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [groupedTransaction, setGroupedTransactions] = useState<Record<string, ITransaction[]>>({});
	const [isMobile] = useDevices();
	const styles = useMemo(() => _styles(isMobile), [isMobile]);
	const Title = isMobile ? Title22 : Title36;

	const userlanguage = useSelector((state: GlobalState) => state.auth?.userInfo?.language);

	const prevSelectedTab = useRef(-1);

	// selectors
	const transactions = useSelector((state: GlobalState) => state.transactions.transactions);
	const pageInfo = useSelector((state: GlobalState) => state.transactions.pageInfo);

	// useEffects
	useEffect(() => {
		setTabs(transactionVM.getInitTransactionsTabs());
		handleTransactions();
	}, []);

	useEffect(() => {
		transactionVM.setTransactions(transactions);
		setGroupedTransactions(transactionVM.getGroupedTransactions());
	}, [transactions]);

	useEffect(() => {
		tabs.length > 0 && setTabs(transactionVM.getTranslatedTransactionsTabs(tabs));
		transactionVM.setTransactions(transactions);
		transactionVM.filterTransactions(transactions, currentTab);
		setGroupedTransactions(transactionVM.getGroupedTransactions());
	}, [currentTab, userlanguage]);

	const handleTransactions = () => {
		dispatch(LoaderActions.setLoading(true));
		dispatch(
			TransactionsThunks.getTransactions({
				first: transactionPerPage,
			})
		)
			// @ts-ignore
			.finally(() => {
				dispatch(LoaderActions.setLoading(false));
			});
	};

	const handleFirstPage = () => {
		dispatch(TransactionsThunks.getTransactions({ first: transactionPerPage }))
			// @ts-ignore
			.finally(() => {
				setIsLast(false);
			});
	};

	const handlePrevious = () => {
		dispatch(TransactionsThunks.getTransactions({ last: transactionPerPage, before: pageInfo.startCursor }))
			// @ts-ignore
			.finally(() => {
				setIsLast(false);
			});
	};

	const handleNext = () => {
		dispatch(TransactionsThunks.getTransactions({ first: transactionPerPage, after: pageInfo.endCursor }));
	};

	const onTabSelected = (tabIndex: number) => {
		if (prevSelectedTab.current === tabIndex) return;
		prevSelectedTab.current = tabIndex;
		setTabs(transactionVM.getTransactionsTabs(tabIndex));
		setCurrentTab(tabIndex);
	};

	return (
		<View style={styles.container}>
			<Title testID={TestIDs.home.balance.texts.transactionTitle} style={styles.title}>
				{I18n.t("refill.myReceipts")}
			</Title>
			<View style={styles.tabsContainer}>
				<TransactionTabs onTabSelected={onTabSelected} tabs={tabs} styleContainer={styles.tabMargin} />
				<TransactionsList navigation={navigation} groupedTransactions={groupedTransaction} currentTab={currentTab} />
				<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
					<ValidButton
						title={I18n.t("refill.firstPageReceipts")}
						onPress={handleFirstPage}
						isDisabled={!isLast && !pageInfo?.hasPreviousPage}
						style={{ marginTop: 32, marginRight: 16 }}
						textStyle={{
							paddingHorizontal: 24,
							paddingVertical: 8,
						}}
					/>
					<ValidButton
						title={I18n.t("refill.previousReceipts")}
						onPress={handlePrevious}
						isDisabled={!isLast && !pageInfo?.hasPreviousPage}
						style={{ marginTop: 32, marginRight: 16 }}
						textStyle={{
							paddingHorizontal: 24,
							paddingVertical: 8,
						}}
					/>
					<ValidButton
						title={I18n.t("refill.nextReceipts")}
						onPress={handleNext}
						isDisabled={isLast || !pageInfo?.hasNextPage}
						style={{ marginTop: 32, marginRight: 16 }}
						textStyle={{
							paddingHorizontal: 24,
							paddingVertical: 8,
						}}
					/>
				</View>
			</View>
		</View>
	);
});

const _styles = (isMobile: boolean) =>
	StyleSheet.create({
		container: {
			marginTop: Spacing.L,
			paddingBottom: isMobile ? Spacing.L : Spacing.XL,
			backgroundColor: Colors.background1,
			borderRadius: 8,
			marginBottom: isMobile ? Spacing.XL : 0,
		},
		title: {
			marginTop: isMobile ? Spacing.XL : 40,
			color: Colors.foodiBlack,
			fontFamily: "manrope-bold",
		},
		tabsContainer: {
			flex: 1,
			marginTop: isMobile ? Spacing.L : Spacing.XL,
			alignItems: "center",
		},
		tabMargin: {
			paddingRight: Spacing.XL,
		},
	});
