"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_USER_PROFILE = void 0;
const client_1 = require("@apollo/client");
exports.GET_USER_PROFILE = client_1.gql `
  query getUserProfile {
    getUser {
      id
      email
      idHoldingView
      firstName
      lastName
      hardLinked
      language
      mfaEnabled
      userOptins {
        optin {
          code
        }
        optinOptions {
          minBalance
        }
      }
      userOptouts {
        code
      }
      guests {
        edges {
          node {
            id
            holding {
              id
              name
              brand
            }
            supportSerialNumber
            managementMode
            virtualTicket
            refillAllowed
          }
        }
      }
      currentHoldingView {
        holding {
          id
          name
          brand
          isBadgeRequired
          isScolaPassCashSystem
          refillAllowed
          externalUrl
          cashSystemInfo {
            id
          }
          isSchool
          refillAllowed
          rating
          brandModel {
            name
          }
        }
        guest {
          supportSerialNumber
          serialNumber
        }
      }
    }
  }
`;
